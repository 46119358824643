import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { sensorType } from '../../config/sensorTypeEnum';
import { useDispatch, useSelector } from 'react-redux';
import CustomizedTables, {
  createData,
} from 'shared-components/src/components/displayData/CustomizedTables.component';
import {
  addSensorRequest,
  deleteSensorRequest,
} from '../../store/actions/sensors.actions';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from 'shared-components/src/components/confirmationModal/confirmationModal.component';
import UpdateSensorsComponent from '../updateSensors/updateSensors.component';
import MapDrawMarkerComponent from "shared-components/src/components/map/mapDrawMarker.component";

const AddSensorsComponent = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const waterSystems = useSelector((state) => state?.waterSystems?.data);
  const initSensors = waterSystems
    ? waterSystems.flatMap((waterSystem) =>
      (waterSystem?.attributes?.sensors?.data || []).map((sensor) => ({
        ...sensor,
        system: waterSystem,
      }))
    )
    : [];
  const [sensors, setSensors] = useState(initSensors);
  const [newSensorType, setSensorType] = useState('');
  const [newSensorWaterSystem, setSensorWaterSystem] = useState('');
  const dispatch = useDispatch();
  const sensorLoading = useSelector((state) => state?.sensors?.loading);
  const sensorAddError = useSelector((state) => state?.sensors?.error);
  const [isModalOpen, setModalOpen] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState(null);
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [sensorToUpdate, setSensorToUpdate] = useState(null);

  const initialValues = {
    waterSystem: '',
    type: '',
    eui: '',
    position: [],
    name: '',
    latitude: '',
    longitude: '',
  };

  const validationSchema = Yup.object({
    waterSystem: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    eui: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    latitude: Yup.string()
      .required('Required')
      .test('is-valid-latitude', 'Invalid latitude format', function (value) {
        if (!value) return false;
        const floatValue = parseFloat(value);
        return (
          !isNaN(floatValue) &&
          floatValue >= -90 &&
          floatValue <= 90 &&
          value.includes('.')
        );
      }),
    longitude: Yup.string()
      .required('Required')
      .test('is-valid-longitude', 'Invalid longitude format', function (value) {
        if (!value) return false;
        const floatValue = parseFloat(value);
        return (
          !isNaN(floatValue) &&
          floatValue >= -180 &&
          floatValue <= 180 &&
          value.includes('.')
        );
      }),
  });

  const handleUpdateClick = (sensor) => {
    setSensorToUpdate(sensor);
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setSensorToUpdate(null);
    setUpdateDialogOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (value) => {
    if (value) {
      const newSensor = {
        id: uuidv4(),
        type: value.type,
        eui: value.eui,
        position: [value.latitude, value.longitude],
        name: value.name,
        water_system: value.waterSystem,
      };
      dispatch(addSensorRequest(newSensor)).catch((error) => {
        console.error("Errore durante l'aggiunta del sensore:", error);
      });
    }
    setOpen(false);
  };

  useEffect(() => {
    if (!sensorLoading && !sensorAddError) {
      setOpen(false);
      setUpdateDialogOpen(false);
    }
  }, [sensorLoading, sensorAddError]);

  useEffect(() => {
    if (waterSystems) {
      const sensors = waterSystems.flatMap((waterSystem) =>
        (waterSystem?.attributes?.sensors?.data || []).map((sensor) => ({
          ...sensor,
          system: waterSystem,
        }))
      );
      setSensors(sensors);
    }
  }, [waterSystems]);

  const deleteSensor = (value) => {
    console.info('deleteSensor', value);
    if (value) {
      dispatch(deleteSensorRequest(value));
    }
  };

  const handleDeleteClick = (value) => {
    setObjectToDelete(value);
    setModalOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteSensor(objectToDelete);
    setModalOpen(false);
  };

  const handleCancelDelete = () => {
    setObjectToDelete(null);
    setModalOpen(false);
  };

  const createRowDataObject = (data) => {
    const attributes = {
      name: data?.attributes?.name,
      system: data?.system?.attributes?.name,
      type: data?.attributes?.type,
      eui: data?.attributes?.eui,
      position: `lat: ${data?.attributes?.position?.[0]}, lng: ${data?.attributes?.position?.[1]}`,
      createdAt: data?.attributes?.createdAt,
      updatedAt: data?.attributes?.updatedAt,
    };
    const result = Object.keys(attributes).map((key) =>
      createData(key, attributes[key])
    );
    return result;
  };

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p>{t('setup-sensors-page.add.title')}</p>
            <h5 onClick={handleClickOpen} style={{ cursor: 'pointer' }}>
              {t('setup-sensors-page.add.button')}
            </h5>
          </div>
        </Grid>
        {sensors.map((sensor, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              key={index}
              style={{
                background: '#f6f6f6',
                width: '100%',
                paddingBottom: '20px',
                marginBottom: '60px',
                marginLeft: '5px',
                paddingRight: '15px',
              }}
            >
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontWeight: 'bold',
                  }}
                >
                  <div>{`${sensor.attributes.name} - ${sensor.system.attributes.name}`}</div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      fontWeight: 'bold',
                    }}
                  >
                    <div
                      onClick={() => handleUpdateClick(sensor)}
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                    >
                      <CreateIcon fontSize="small" />
                    </div>
                    <div
                      onClick={() => handleDeleteClick(sensor)}
                      style={{ cursor: 'pointer' }}
                    >
                      <DeleteIcon fontSize="small" />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <CustomizedTables
                  rows={createRowDataObject(sensor)}
                ></CustomizedTables>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '70%',
          },
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {(formikDialogProps) => (
            <Form>
              <DialogTitle id="alert-dialog-title">
                {t('setup-sensors-page.add.button')}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={2}
                    style={{ marginTop: '20px' }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={2}
                      >

                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl fullWidth>
                            <InputLabel id="water-system-select-label">
                              {t('setup-sensors-page.add.source-user-label')}
                            </InputLabel>
                            <Select
                              label="Type of sensor"
                              id={`waterSystem`}
                              name={`waterSystem`}
                              labelId="water-system-select-label"
                              value={newSensorWaterSystem}
                              onChange={(e) => {
                                setSensorWaterSystem(e.target.value);
                                formikDialogProps.setFieldValue(
                                  'waterSystem',
                                  e.target.value
                                );
                              }}
                            >
                              <MenuItem value="">
                                <em>{t('none')}</em>
                              </MenuItem>
                              {Object.values(waterSystems).map((waterSystem, i) => (
                                <MenuItem key={i} value={waterSystem.id}>
                                  {t(waterSystem.attributes.name)}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="waterSystem" component="div" />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl fullWidth>
                            <InputLabel id="type-select-label">
                              {t('setup-sensors-page.add.type-sensor-label')}
                            </InputLabel>
                            <Select
                              label={t('setup-sensors-page.add.type-sensor-label')}
                              id={`type`}
                              name={`type`}
                              labelId="type-select-label"
                              value={newSensorType}
                              onChange={(e) => {
                                setSensorType(e.target.value);
                                formikDialogProps.setFieldValue(
                                  'type',
                                  e.target.value
                                );
                              }}
                            >
                              <MenuItem value="">
                                <em>{t('none')}</em>
                              </MenuItem>
                              {Object.values(sensorType).map((option, i) => (
                                <MenuItem key={i} value={option}>
                                  {t(option)}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="type" component="div" />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Field
                            as={TextField}
                            label={t('setup-sensors-page.add.name')}
                            name={`name`}
                            variant="outlined"
                            fullWidth
                          />
                          <ErrorMessage name={`name`} component="div" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Field
                            as={TextField}
                            label={t('setup-sensors-page.add.device-eui')}
                            name={`eui`}
                            variant="outlined"
                            fullWidth
                          />
                          <ErrorMessage name={`eui`} component="div" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Field
                            as={TextField}
                            label={t('setup-sensors-page.add.latitude')}
                            name={`latitude`}
                            variant="outlined"
                            fullWidth
                            error={
                              formikDialogProps.errors.latitude &&
                              formikDialogProps.touched.latitude
                            }
                            helperText={
                              formikDialogProps.errors.latitude &&
                                formikDialogProps.touched.latitude
                                ? formikDialogProps.errors.latitude
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Field
                            as={TextField}
                            label={t('setup-sensors-page.add.longitude')}
                            name={`longitude`}
                            variant="outlined"
                            fullWidth
                            error={
                              formikDialogProps.errors.longitude &&
                              formikDialogProps.touched.longitude
                            }
                            helperText={
                              formikDialogProps.errors.longitude &&
                                formikDialogProps.touched.longitude
                                ? formikDialogProps.errors.longitude
                                : ''
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <MapDrawMarkerComponent
                        markerPosition={{
                          lat: formikDialogProps?.values?.latitude,
                          lng: formikDialogProps?.values?.longitude,
                        }}
                        onMarkerPositionChange={((value) => {
                          formikDialogProps.setFieldValue(
                            "latitude",
                            value?.lat || "",
                          )
                          formikDialogProps.setFieldValue(
                            "longitude",
                            value?.lng || "",
                          )
                        })}>
                      </MapDrawMarkerComponent>

                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>
                  {t('setup-sensors-page.add.cancel')}
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  {t('setup-sensors-page.add.btn-add')}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      {sensorToUpdate && (
        <UpdateSensorsComponent
          sensor={sensorToUpdate}
          open={isUpdateDialogOpen}
          onClose={handleUpdateDialogClose}
        />
      )}
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={t('sensors_form.confirmational_modal.title')}
        message={t('sensors_form.confirmational_modal.message')}
        cancelText={t('sensors_form.confirmational_modal.cancel_text')}
        confirmText={t('sensors_form.confirmational_modal.confirm_text')}
      />
    </Fragment>
  );
};

export default AddSensorsComponent;
