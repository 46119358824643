const host = process.env.REACT_APP_BACKEND_URL || "http://localhost:1337";
// const host = "https:///backend.acquaount.development.abidevops.website";

export const weatherApiKey = "c45d67942b6f42a4ac3123410240807";

const api_root = "/api";
export const API_URL_LOGIN = `${host}${api_root}/auth/local`;
export const API_GET_ME = `${host}${api_root}/users/me`;
export const API_WATER_AUTHORITY = `${host}${api_root}/water-authorities`;
export const API_WATER_SYSTEMS = `${host}${api_root}/water-systems`;
export const API_GET_FIELDS_DATA = `${host}${api_root}/fieldsFromFarm`;
export const API_ADD_FIELD_DATA = `${host}${api_root}/fields`;
export const API_UPDATE_FIELD_DATA = `${host}${api_root}/fields`;
export const API_POPULATE_ALL = `?populate=*`;
export const API_SENSORS = `${host}${api_root}/sensors`;
export const API_WEATHER_DATA = `${host}${api_root}/get-weather-data`;
export const API_SENSOR_MEASURE_DATA = `${host}${api_root}/get-measures-by-time-range`;
export const API_ALL_SENSORS_MEASURE = `${host}${api_root}/get-all-sensors-measure`;
export const API_AGGREGATE_MONTH_MEASURE = `${host}${api_root}/get-datastream-aggregate-measure`;
export const API_DATA_FROM_MODEL = `${host}${api_root}/get-data-from-model`;
